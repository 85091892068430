import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Em, Strong, Icon, Box, Section } from "@quarkly/widgets";
import { MdLocalPhone, MdLocationOn } from "react-icons/md";
const defaultProps = {
	"padding": "120px 0 120px 0",
	"md-padding": "80px 0 90px 0",
	"quarkly-title": "Headline-2",
	"background": "linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0, 0, 0, 0.75) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z) 0% 0% /cover repeat scroll padding-box"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "--light",
			"sm-font": "normal 700 42px/1.2 --fontFamily-sans",
			"font": "--headline1",
			"children": <>
				<Strong>
					Вітаємо вас на сайті "Нотаріус Професіонал"
				</Strong>
				{"\n"}
			</>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 60% 80px 0px",
			"font": "normal 300 20px/1.5 --fontFamily-sansHelvetica",
			"lg-margin": "0px 40% 50px 0px",
			"md-margin": "0px 0 50px 0px",
			"width": "60%",
			"sm-width": "100%",
			"color": "--light",
			"children": "Ми раді вітати вас на нашому сайті! Наша нотаріальна контора пропонує широкий спектр послуг, які допоможуть вам у вирішенні юридичних питань. Ми гарантуємо професійний підхід до кожного клієнта та швидке виконання всіх нотаріальних дій. Довіра клієнтів для нас – найвища нагорода."
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "25px 25px",
			"md-grid-template-columns": "1fr",
			"display": "block",
			"margin": "0px auto 0px 0"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "auto",
			"display": "flex",
			"padding": "25px 20px 25px 20px",
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "rgba(74, 74, 108, 0.4)",
			"border-radius": "25px",
			"lg-flex-direction": "column",
			"background": "--color-darkL2",
			"align-self": "center",
			"margin": "0px 0px 1rem 0px"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdLocalPhone,
			"size": "24px",
			"min-width": "44px",
			"margin": "0px 14px 0px 0px",
			"lg-margin": "0px 0 18px 0px",
			"lg-justify-content": "flex-start",
			"color": "--light"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "auto"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"color": "--light",
			"font": "normal 500 22px/1.2 --fontFamily-sans",
			"children": "0675762295"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "auto",
			"display": "flex",
			"padding": "25px 20px 25px 20px",
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "rgba(74, 74, 108, 0.4)",
			"border-radius": "25px",
			"lg-flex-direction": "column",
			"background": "--color-darkL2",
			"align-self": "center"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdLocationOn,
			"size": "24px",
			"min-width": "44px",
			"margin": "0px 14px 0px 0px",
			"lg-margin": "0px 0 18px 0px",
			"lg-justify-content": "flex-start",
			"color": "--light"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "auto"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"color": "--light",
			"font": "normal 500 22px/1.2 --fontFamily-sans",
			"children": "Chornovola St, 2, Ternopil, Ternopil Oblast, 46001"
		}
	}
};

const Hero = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" sm-min-width="280px" />
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Icon {...override("icon")} />
				<Box {...override("box2")}>
					<Text {...override("text2")} />
				</Box>
			</Box>
			<Box {...override("box3")}>
				<Icon {...override("icon1")} />
				<Box {...override("box4")}>
					<Text {...override("text3")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Hero, { ...Section,
	defaultProps,
	overrides
});
export default Hero;