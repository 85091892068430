import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Strong } from "@quarkly/widgets";
import QuarklycommunityKitMarquee from "./QuarklycommunityKitMarquee";
const defaultProps = {
	"width": "100%",
	"padding": "1rem 0px 1rem 0px",
	"background": "--color-primary",
	"color": "--light",
	"text-transform": "uppercase",
	"font": "--lead",
	"flex-direction": "row",
	"display": "flex",
	"justify-content": "space-around"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"width": "100%",
			"min-width": "45%",
			"children": <Strong>
				ТЕЛЕФОНУЙТЕ ЗА НОМЕРОМ 0675762295
			</Strong>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"width": "100%",
			"min-width": "45%",
			"children": <Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				ТЕЛЕФОНУЙТЕ ЗА НОМЕРОМ 0675762295
			</Strong>
		}
	},
	"Container": {
		"props": {
			"display": "flex",
			"justify-content": "space-around",
			"width": "100%"
		}
	}
};

const Marque = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <QuarklycommunityKitMarquee {...rest}>
		<Text {...override("text")} />
		<Text {...override("text1")} />
		{children}
	</QuarklycommunityKitMarquee>;
};

Object.assign(Marque, { ...QuarklycommunityKitMarquee,
	defaultProps,
	overrides
});
export default Marque;