import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "10px 0 10px 0",
	"quarkly-title": "Footer-2"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"sm-flex-direction": "column",
			"text-align": "center"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px auto 0px auto",
			"font": "--base",
			"color": "#5a5d64",
			"sm-margin": "0px 0px 15px 0px",
			"sm-text-align": "center",
			"children": "© 2024 Нотаріус Професіонал. All rights reserved."
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Text {...override("text")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;